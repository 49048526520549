:root {
  --theme: blanchedalmond;
  --theme2: rgb(189, 172, 148);
  --theme3: rgb(59, 54, 47);
  --theme4: rgb(24, 23, 20);
  --time: 2s;
}

body {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(45deg, black, #353434);
  color: var(--theme);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
}


.name h1 {
  margin: 0 0 1em;
  font-size: xxx-large;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* Internet Explorer */
}

.name h1 .m, .name h1 .a, .name h1 .r, .name h1 .z, .name h1 .u, .name h1 .k, .name h1 .M, .name h1 .A, .name h1 .S, .name h1 .H, .name h1 .R, .name h1 .AA, .name h1 .F, .name h1 .E {
  -webkit-transition: 2s;
  transition: 2s;
}

.name h1 .m:hover, .name h1 .a:hover, .name h1 .r:hover, .name h1 .z:hover, .name h1 .u:hover, .name h1 .k:hover, .name h1 .M:hover,
.name h1 .A:hover, .name h1 .S:hover, .name h1 .H:hover, .name h1 .R:hover, .name h1 .AA:hover, .name h1 .F:hover, .name h1 .E:hover {
  cursor: default;
  -webkit-transition: .1s;
  transition: .1s;
  font-size: 120%;
  color: var(--theme3);
}

.name h1 .xyzxx {
  font-size: 120%;
  visibility: hidden;
}

.name h1 .m {
  -webkit-animation-name: m;
          animation-name: m;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .a {
  -webkit-animation-name: a;
          animation-name: a;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .r {
  -webkit-animation-name: rr;
          animation-name: rr;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .z {
  -webkit-animation-name: z;
          animation-name: z;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .u {
  -webkit-animation-name: u;
          animation-name: u;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .k {
  -webkit-animation-name: k;
          animation-name: k;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .M {
  -webkit-animation-name: M;
          animation-name: M;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .A {
  -webkit-animation-name: A;
          animation-name: A;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .S {
  -webkit-animation-name: S;
          animation-name: S;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .H {
  -webkit-animation-name: H;
          animation-name: H;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .R {
  -webkit-animation-name: RR;
          animation-name: RR;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .AA {
  -webkit-animation-name: AA;
          animation-name: AA;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .F {
  -webkit-animation-name: F;
          animation-name: F;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

.name h1 .E {
  -webkit-animation-name: E;
          animation-name: E;
  -webkit-animation-duration: var(--time);
          animation-duration: var(--time);
}

@-webkit-keyframes m {
  30% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  35% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes m {
  30% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  35% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes a {
  35% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  40% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes a {
  35% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  40% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes rr {
  40% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  45% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes rr {
  40% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  45% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes z {
  45% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  50% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes z {
  45% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  50% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes u {
  50% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  55% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes u {
  50% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  55% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes k {
  55% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  60% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes k {
  55% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  60% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes M {
  60% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  65% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes M {
  60% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  65% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes A {
  65% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  70% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes A {
  65% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  70% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes S {
  70% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  75% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes S {
  70% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  75% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes H {
  75% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  80% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes H {
  75% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  80% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes RR {
  80% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  85% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes RR {
  80% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  85% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes AA {
  85% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  90% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes AA {
  85% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  90% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes F {
  90% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  95% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes F {
  90% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  95% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@-webkit-keyframes E {
  95% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  100% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}

@keyframes E {
  95% {
    cursor: default;
    font-size: 100%;
    color: var(--theme3);
  }
  100% {
    cursor: default;
    font-size: 120%;
    color: var(--theme);
  }
}